/* index.css or App.css */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
body {
    margin: 0;
    font-family: "Manrope", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    display: none;
}


/* Background colors */

.travelmate-light-black {
    background-color: #3a3737;
}