.button {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'Cormorant Garamond', serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Lora', serif;
}

.button svg {
  height: 18px;
}

.normal {
  color: black;
  background-color: #99ff66;
  border-radius: 5px;
  border: 2px solid #99ff66;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  font-weight: bold;
  color: #333333;
  text-decoration: none;
}

.icon {
  padding-right: 5px;
}