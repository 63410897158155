/* Dashboard Sidebar Styles */

.dashboard__sidebar {
  width: 250px;
  /* height: 100vh; */
  /* border-right: 0.6px solid; */
}

.dashboard__sidebar.collapsed {
  width: 80px;
}

.dasboard__sidebar__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.dashboard__drawer__collapse__icon {
  font-size: 1.5rem;
  display: flex;
  cursor: pointer;
}

.dashboard__drawer__collapse__icon:hover {
  border: 1px solid #fff;
}

.dashboard__sidebar__link {
  font-size: 15px;
}

.dashboard__sidebar__section {
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 768px) {
  .dashboard__sidebar {
    width: 50px;
  }
  .dashboard__sidebar__linktitle {
    display: none;
  }
  .dashboard__sidebar__section {
    display: none;
  }
}
