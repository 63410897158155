/* varibles */

:root {
  --travelmate__green: #44cf6c;
  --travelmage__light__green: #a9fdac;
  --travelmate__black: #1b1a1a;
  --travelmate__white: #ffffff;
  --travelmate__off__white: #f5f5f5;
}

.settings__sidebar {
  width: 130px;
  padding: 10px;
  height: 100vh;
  border-right: var(--travelmate__white) 1px solid;
}

.settings__sidebarlink {
  font-size: 14px;
  opacity: 0.7;
}

.settings__sidebarlink:hover {
  opacity: 1;
  border-radius: 8px;
}

.settings__icon {
  opacity: 0.8;
}

.settings__sub__h3 {
  font-size: clamp(14px, 2rem, 18px);
  opacity: 0.8;
}

/* reset password styles */

.resetpassword__container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.resetpassword__body {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.resetpassword__text {
  width: 30vw;
}

.resetpassword__input__div {
  width: 30vw;
}

.resetpassword__button {
  display: flex;
  justify-content: flex-end;
}

/* Delete account  settings */

.deleteaccount__container {
  padding: 20px;
}

.delete__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.ant-tabs-tab {
  color: #999 !important;
}

@media screen and (max-width: 768px) {
  .settings__sidebar {
    width: 80px;
    display: none;
  }
  .settings__sidebarlink {
    font-size: 12px;
  }
  .resetpassword__text {
    width: 100%;
  }
  .resetpassword__input__div {
    width: 100%;
  }
  .resetpassword__button {
    justify-content: flex-start;
  }
}
