.main {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    width: 100%;
    height: 70px;
    padding: 15px 0 15px 0;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 2px -2px #949494;
}

.main a {
    text-decoration: none;
}

.main a:visited {
    color: inherit;
}

.main .logo {
    padding-left: 15px;
    letter-spacing: 0.05ch;
}

.avatar {
    height: 24px;
    padding-right: 15px;
}