.profile__progressbar {
  width: 89%;
  margin-left: 35px;
}

.profile__container {
  width: 100%;
}

.profile__container__div {
  width: 50%;
}

.profile__field__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.profile__disabled {
  background-color: #e7e7e7;
}

.profile__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}

.profile__label__sub {
  opacity: 0.6;
  margin-bottom: 2px;
}

.profile__input {
  width: 100%;
  font-size: 15px;
  padding: 5px;
  border: 1px solid #c8bdbd;
  border-radius: 5px;
}

.profile__input__datepicker {
  width: 100%;
  border: none;
}

.profile__bio {
  height: 80px;
  font-size: 15px;
  resize: none;
}

.ant-select-selector {
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.ant-select-selection-search {
  background-color: transparent !important;
}

/* .ant-select-selection-item {
    background-color: #ddd !important;
} */

.ant-select-selection-item-content {
  color: black;
}

@media only screen and (max-width: 768px) {
  .profile__container {
    width: 100%;
  }
  .profile__field__container {
    flex-direction: column;
    align-items: flex-start;
  }
  .profile__input {
    width: 100%;
    font-size: 14px;
  }
  .profile__label {
    font-size: 13px;
  }
  .profile__container__div {
    width: 100%;
  }
}
